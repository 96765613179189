<template>
  <div>
    <div id="breadcrumbs">
      <div class="left">
        <a :aria-label="$t('files.home')" :title="$t('files.home')" @click="setDirection('/')">
          <i class="material-icons">home</i>
        </a>

        <span v-for="(link, index) in breadcrumbs" :key="index">
          <span class="chevron"><i class="material-icons">keyboard_arrow_right</i></span>
          <span v-if="index===breadcrumbs.length-1">{{ link.name }}</span>
          <a v-else @click="setDirection(link.url)">{{ link.name }}</a>
        </span>
      </div>
      <!-- <div class="right">
        <v-radio-group v-model="rootDirection" row>
          <v-radio v-for="root in rootDirectionOptions" :key="root.index" :label="root.text" :value="root.value"></v-radio>
        </v-radio-group>        
      </div> -->
    </div>
    <div v-if="error">
      <not-found v-if="error.message === '404'"></not-found>
      <forbidden v-else-if="error.message === '403'"></forbidden>
      <internal-error v-else></internal-error>
    </div>
    <!-- <editor v-else-if="isEditor"></editor> -->
    <listing v-else-if="isListing" :class="{ multiple }"></listing>
    <preview v-else-if="isEditor||isPreview"
      @download-clicked="setFileBrowserValues"
    />
    <!-- <div v-else>
      <h2 class="message">
        <span>{{ $t('files.loading') }}</span>
      </h2>
    </div> -->
  </div>
</template>

<script>
import __C from '@/primitives/_constant_'
import Forbidden from './errors/403'
import NotFound from './errors/404'
import InternalError from './errors/500'
import Preview from '../components/files/Preview'
import Listing from '../components/files/Listing'
import { FilebrowserService } from '../services'
import { mapGetters, mapState, mapMutations } from 'vuex'
import { FetchStreamService } from "@/services"

// function clean (path) {
//   return path.endsWith('/') ? path.slice(0, -1) : path
// }

export default {
  name: 'files',
  components: {
    Forbidden,
    NotFound,
    InternalError,
    Preview,
    Listing,
    // Editor: () => import('../components/files/Editor')
  },
  computed: {
    ...mapState(__C.STORE_NAMESPACE.ACCOUNT, ['account']),    
    ...mapGetters([
      'selectedCount',
      'isListing',
      'isEditor',
      'isFiles'
    ]),
    ...mapState([
      'req',
      'user',
      'reload',
      'multiple',
      'loading',
      'fbpath',
      'fbname',
      'sort'
    ]),
    isPreview () {
      return !this.loading && !this.isListing && !this.isEditor
    },
    breadcrumbs () {
      let parts = this.fbpath.split('/')

      if (parts[0] === '') {
        parts.shift()
      }

      if (parts[parts.length - 1] === '') {
        parts.pop()
      }

      let breadcrumbs = []

      for (let i = 0; i < parts.length; i++) {
        if (i === 0) {
          breadcrumbs.push({ name: decodeURIComponent(parts[i]), url: '/' + parts[i] })
        } else {
          breadcrumbs.push({ name: decodeURIComponent(parts[i]), url: breadcrumbs[i - 1].url + '/' + parts[i] })
        }
      }

      return breadcrumbs
    }
  },
  data: function () {
    return {
      fetchStreamService: null,
      filebrowserService: null,
      error: null,
      rootDirection: 'public',
      rootDirectionOptions: [
        { text: 'Web Public', value: 'public'},
        { text: 'Private Sharing', value: 'private'}
      ]
    }
  },
  created () {
    this.filebrowserService = new FilebrowserService()
    this.fetchStreamService = new FetchStreamService()
    this.fetchData()
  },
  watch: {
    'fbpath': 'fetchData',
    'reload': function () {
      this.fetchData()
    },
    fbname() {
      this.fetchData()
    }
  },
  mounted () {
    window.addEventListener('keydown', this.keyEvent)
    window.addEventListener('scroll', this.scroll)
  },
  beforeDestroy () {
    window.removeEventListener('keydown', this.keyEvent)
    window.removeEventListener('scroll', this.scroll)
  },
  destroyed () {
    this.$store.commit('updateRequest', {})
  },
  methods: {
    ...mapMutations([ 'setLoading', 'setDirection' ]),

    fetchData () {
      // Reset view information.
      // this.$store.commit('setReload', false)
      this.$store.commit('resetSelected')
      this.$store.commit('multiple', false)
      this.$store.commit('closeHovers')

      // Set loading to true and reset the error.
      this.setLoading(true)
      this.error = null

      let url = this.fbpath
      if (url === '') url = '/'
      if (url[0] !== '/') url = '/' + url

      this.fetchStreamService.selectedFile(this.account.token, url, this.fbname, this.sort).then(res => {
        if (res.isDir) {
          res.items = res.items.map((item, index) => {
            item.index = index
            item.url = `${url}${url.length > 1 ? '/' : ''}${encodeURIComponent(item.name)}`
            return item
          })
        }

        this.setLoading(false)

        // if (clean(res.path) !== clean(`/${this.$route.params.pathMatch}`)) {
        //   return
        // }

        this.$store.commit('updateRequest', res)
      })      


      // this.filebrowserService.getFileResources(url, this.fbname, this.sort).then(res => {
      //   console.log(res)
      //   if (res.isDir) {
      //     res.items = res.items.map((item, index) => {
      //       item.index = index
      //       item.url = `${url}${url.length > 1 ? '/' : ''}${encodeURIComponent(item.name)}`
      //       return item
      //     })
      //   }

      //   this.setLoading(false)

      //   // if (clean(res.path) !== clean(`/${this.$route.params.pathMatch}`)) {
      //   //   return
      //   // }

      //   this.$store.commit('updateRequest', res)
      // })
    },

    setFileBrowserValues(v) {
      this.$emit('download-clicked', v)
    },

    keyEvent (event) {
      // Esc!
      if (event.keyCode === 27) {
        this.$store.commit('closeHovers')

        // If we're on a listing, unselect all
        // files and folders.
        if (this.isListing) {
          this.$store.commit('resetSelected')
        }
      }

      // Del!
      if (event.keyCode === 46) {
        if (this.isEditor ||
          !this.isFiles ||
          this.loading ||
          (this.isListing && this.selectedCount === 0) ||
          this.$store.state.show != null) return

        this.$store.commit('showHover', 'delete')
      }

      // F1!
      if (event.keyCode === 112) {
        event.preventDefault()
        this.$store.commit('showHover', 'help')
      }

      // F2!
      if (event.keyCode === 113) {
        if (this.isEditor ||
          !this.isFiles ||
          this.loading ||
          !this.user.perm.rename ||
          (this.isListing && this.selectedCount === 0) ||
          (this.isListing && this.selectedCount > 1)) return

        this.$store.commit('showHover', 'rename')
      }

      // CTRL + S
      if (event.ctrlKey || event.metaKey) {
        if (this.isEditor) return

        if (String.fromCharCode(event.which).toLowerCase() === 's') {
          event.preventDefault()

          if (this.req.kind !== 'editor') {
            document.getElementById('download-button').click()
          }
        }
      }
    },
    scroll () {
      if (this.req.kind !== 'listing' || this.$store.state.user.viewMode === 'mosaic') return

      let top = 112 - window.scrollY

      if (top < 64) {
        top = 64
      }

      document.querySelector('#listing.list .item.header').style.top = top + 'px'
    },
    openSidebar () {
      this.$store.commit('showHover', 'sidebar')
    },
    openSearch () {
      this.$store.commit('showHover', 'search')
    }
  }
}
</script>
